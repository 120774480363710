import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "nav" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_web3_modal_vue = _resolveComponent("web3-modal-vue")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_web3_modal_vue, {
        ref: "web3modal",
        disableInjectedProvider: true,
        providerOptions: _ctx.providerOptions,
        cacheProvider: true,
        network: "binance"
      }, null, 8, ["providerOptions"])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}