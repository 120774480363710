export default {
  diba: {
    97: '0xBBA9881e71dd2ad55cA7D4884965F85398991Aa2',
    56: '0xE5094E58042e5332CfD25Ed18e4d805e86Fb8f05',
    5777: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  dibaAir: {
    97: '0x08Dc600eD072b30a1362702DE76B37599947f4E7',
    56: '0xDa8B328690C59D436741CcdCd6B45451011DB97B',
    5777: '',
  }
}
